import React from "react"
import { Col, Container, Row } from "styled-bootstrap-grid"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"

const QuoteStyled = styled.h2`
  ${fonts.avantGardeBold};
  font-size: 36px;
  text-transform: uppercase;
  color: ${colors.blue};
  padding-right: 50px;
  z-index: 1;
  margin-bottom: 40px;
  @media(min-width: ${breakpoints.md}) {
    max-width: 780px;
    font-size: 40px;
    margin-bottom: 60px;
  }
  @media(min-width: ${breakpoints.lg}) {
    font-size: 60px;
    max-width: 900px;
    padding-left: 40px;
  }
`
const ColStyled = styled(Col)`
  display: grid;
  justify-content: center;
  align-items: center;
  div {
    padding: 20px 10px;
    margin: 0 auto;
  }
`
const RowStyled = styled(Row)`
  padding: 0 22px;
  @media (min-width: ${breakpoints.md}) {
    /* border: 1px solid; */
    padding: 30px;
    max-width: 1440px;
    margin: 0 auto;
  }
  @media(min-width: ${breakpoints.lg}) {
    padding-bottom: 100px;
  }
`

const StyledImage = styled.img`
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
`

const StyledLogo = styled(StyledImage)`
  max-width: 100px;
`;

function BrandWork({title, logos}) {
  return (
    <>
      <RowStyled>
        <Col sm={12}>
          <QuoteStyled>{title}</QuoteStyled>
        </Col>
        {logos.map((logo, index) => {
          return (
            <ColStyled xs={6} sm={6} md={4} lg={2} key={index}>
              <div>
                <StyledLogo src={logo.file.url} alt={logo.title}/>
              </div>
            </ColStyled>
          )
        })}
      </RowStyled>
    </>
  )
}

export default BrandWork
