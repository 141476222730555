import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import WhatWeDoBlock from "../Home/WhatWeDoBlock"

const ServiceBlock = styled.div`
  position: relative;
  padding-top: 500px;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 300px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 170px;
  }
`
const ImageContainer = styled.div`
  min-width: 347px;
  max-width: 348px;
  padding-left: ${({ padding }) => (padding ? padding : 33)}px;
  padding-right: ${({ padding }) => (padding ? padding : 33)}px;
  padding-top: 110px;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const ImageBlock = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
    height: 10px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
`
const ImageBlockMobile = styled.div`
  display: block;
  position: absolute;
  top: 10%;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const FirstImage = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: inline-block;
  position: absolute;
    min-width: 360px;
    top: -400px;
    left: 50%;
    z-index: -1;
  }
  @media (min-width: ${breakpoints.lg}) {
    left: 33%;
    min-width: ${({ minWidth }) => minWidth}px;
    z-index: 1;
  }
`
const SecondImage = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: inline-block;
    position: absolute;
    top: -470px;
    right: 2%;
    min-width: ${({ minWidth }) => minWidth}px;
  }
`
const StyledGatsbyImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

function Service({ctaLabel, ctaLink, cards, aboutImages}) {
  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "projects/background.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <ServiceBlock>
      <ImageBlockMobile>
        <ImageContainer>
          <StyledGatsbyImage fluid={aboutImages[0].fluid} />
        </ImageContainer>
      </ImageBlockMobile>
      <ImageBlock>
        <FirstImage minWidth={420}>
          <StyledGatsbyImage fluid={aboutImages[0].fluid} />
        </FirstImage>
        <SecondImage minWidth={215}>
          <StyledGatsbyImage fluid={aboutImages[1].fluid} />
        </SecondImage>
      </ImageBlock>
        <WhatWeDoBlock 
          background={backgroundImage}
          ctaLabel={ctaLabel}
          ctaLink={ctaLink}
          cards={cards}
        />
    </ServiceBlock>
  )
}

export default Service
