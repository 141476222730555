import React from "react";
import { Col, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Button from "../Core/Button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const AboutBox = styled.div`
  border-left: 1px solid ${colors.lightGrey};
  margin-top: -90px;
  margin-bottom: 50px;
  height: 360px;
  width: 100%;
  position: relative;
  z-index: 2;
  @media (min-width: ${breakpoints.md}) {
    height: 250px;
    max-width: 1200px;
    margin: -70px auto 50px;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 407px;
    margin-bottom: 200px;
  }
`

const QuoteStyled = styled.h1`
  ${fonts.avantGardeBold};
  font-size: 36px;
  text-transform: uppercase;
  color: ${colors.blue};
  padding: 80px 50px 40px 46px;
  max-width: 410px;
  @media (min-width: ${breakpoints.md}) {
    max-width: 720px;
    font-size: 40px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 60px;
    max-width: 800px;
    position: relative;
    z-index: 2;
    padding: 170px 50px 40px 20px;
  }
`
const AboutBoxTitle = styled.div`
  ${fonts.avantGardeMedium};
  font-size: 12px;
  letter-spacing: 1.6px;
  color: ${colors.white};
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 39px;
  padding-left: 23.5px;
`

const RowStyled = styled(Row)`
  padding: 33px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 30px;
  }

  p {
    line-height: 24px;
    margin-bottom: 20px;
    font-size: 16px;
    max-width: 620px;
    &:last-of-type {
      margin-bottom: 50px;
    }
    @media (min-width: ${breakpoints.md}) {
      max-width: 340px;
    }
  }
`

function About({
  ctaLabel,
  ctaLink,
  ctaAriaLabel,
  eyebrow, 
  title, 
  content
}) {
  return (
    <RowStyled>
      <AboutBox>
        <AboutBoxTitle>{eyebrow}</AboutBoxTitle>
        <QuoteStyled>{title}</QuoteStyled>
        <Row>
          <Col>
            {documentToReactComponents(content.json)}
          </Col>
          <Button link={ctaLink} aria-label={ctaAriaLabel} blue>
            {ctaLabel}
          </Button>
        </Row>
      </AboutBox>
    </RowStyled>
  )
}

export default About
