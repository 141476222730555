import React from "react"
import { Col } from "styled-bootstrap-grid"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ColStyled = styled(Col)`
  display: grid;
  color: ${colors.white};
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  div {
    margin: 0 auto;
    padding: 20px;
  }
  h3 {
    ${fonts.avantGardeBold};
    font-size: 24px;
    line-height: 24px;
    padding: 10px;
  }
  p {
    ${fonts.avantGardeMedium};
    font-size: 16px;
    line-height: 24px;
    max-width: 272px;
  }
`

const StyledImage = styled.img`
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
`

const WhatWeDoCards = ({ title, image, content }) => {
  return (
    <ColStyled xs={12} sm={6} lg={3}>
      <div>
        <StyledImage src={image.file.url} alt={title}/>
      </div>
      <h3>{title}</h3>
      {documentToReactComponents(content.json)}
    </ColStyled>
  )
}

export default WhatWeDoCards
