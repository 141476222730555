import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import Hero from "../components/Home/Hero"
import About from "../components/Home/About"
import Service from "../components/Home/Service"
import BrandWork from "../components/Home/BrandWork"
import { graphql } from "gatsby"

const IndexPage = ({ location, data }) => {
  const path = location.pathname

  const {
    arrowImage,
    page,
  } = data

  const {
    metaTitle,
    metaDescription,
    heroEyebrow,
    hero,
    aboutCtaLabel,
    aboutCtaLink,
    aboutCtaAriaLabel,
    aboutEyebrow,
    aboutTitle,
    aboutContent,
    aboutImages,
    servicesCtaLabel,
    servicesCtaLink,
    servicesCtaAriaLabel,
    services,
    partnersTitle,
    partnerLogos
  } = page

  return (
    <Layout hero={"true"}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        fullTitle={true}
        path={path}
      />
      <Hero
        hero={hero}
        eyebrow={heroEyebrow}
        arrowImage={arrowImage}
      />
      <About
        ctaLabel={aboutCtaLabel}
        ctaLink={aboutCtaLink}
        ctaAriaLabel={aboutCtaAriaLabel}
        eyebrow={aboutEyebrow}
        title={aboutTitle}
        content={aboutContent}
      />
      <Service 
        aboutImages={aboutImages}
        ctaLabel={servicesCtaLabel}
        ctaLink={servicesCtaLink}
        ctaAriaLabel={servicesCtaAriaLabel}
        cards={services}
      />
      <BrandWork title={partnersTitle} logos={partnerLogos} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    page: contentfulHomepage {
      metaTitle
      metaDescription
      heroEyebrow
      hero {
        title
        image {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        mobileImage {
          fluid(quality: 80, maxWidth: 828) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      aboutCtaLabel
      aboutCtaLink
      aboutCtaAriaLabel
      aboutEyebrow
      aboutTitle
      aboutContent {
        json
      }
      aboutImages {
        fluid(quality: 80, maxWidth: 992, maxHeight: 1280) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      servicesCtaLabel
      servicesCtaLink
      servicesCtaAriaLabel
      services {
        image {
          file {
            url
          }
        }
        title
        content {
          json
        }
      }
      partnersTitle
      partnerLogos {
        file {
          url
        }
      }
    }
    arrowImage: file(relativePath: { eq: "ui/contact-arrow.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
