import React, { Component, useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import Slider from "react-slick"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Slide = styled.div`
  position: relative;
  height: 810px;
  outline: none;
`

const Background = styled(Img)`
  height: 100%;
  width: 100%;
`

const ContentHero = styled.div`
  position: absolute;
  top: 273px;
  padding-left: 32px;
  z-index: 10;
  p {
    color: ${colors.white};
    ${fonts.avantGardeMedium};
    line-height: 19px;
    font-size: 16px;
    padding-bottom: 40px;
    text-align: left;
  }
  li {
    display: block;
    height: auto;
    ${fonts.avantGardeBold};
    font-size: 65px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.white};
    -webkit-text-stroke-width: 1px;
    margin-left: 0;
    padding-bottom: 9px;
  }
  li.slick-active {
    -webkit-text-fill-color: ${colors.white};
  }
  @media (min-width: ${breakpoints.md}) {
    top: 245px;
    padding-left: 107px;
    li {
      font-size: 82px;
      padding-bottom: 10px;
    }
  }
`

const HeroBlock = styled.div`
display: none;
  margin-top: -81px;
  position: relative;
  z-index: 1;
  .slick-slide {
    border: none;
    outline: none;
    height: 810px;
  }
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const Overlay = styled.div`
  height: 810px;
  width: 100%;
  background: ${colors.black};
  opacity: 0.2;
  position: absolute;
  top: 0;
  z-index: 1;
  @media (min-width: ${breakpoints.md}) {
    z-index: 1;
  }
`
const LinkStyled = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
`

const ArrowContainer = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.65;
    z-index: 1;
    max-height: 810px;
    max-width: 717px;
    height: 100%;
    width: 100%;
  }
`
const HeroMobile = styled.div`
  margin-top: -81px;
  position: relative;
  z-index: 1;
  .slick-slide {
    border: none;
    outline: none;
    height: 810px;
  }
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Hero = (props) => {
  const [isLoaded, setIsLoaded] = useState()

  const { arrowImage, hero, eyebrow } = props

  let settings = {
    customPaging: function (index) {
      return (
        <div>{hero[index].title.toLowerCase()}</div>
      )
    }.bind(this),
    appendDots: (dots) => (
      <ContentHero>
        <p>{eyebrow}</p>
        {dots}
      </ContentHero>
    ),
    dots: true,
    arrows: false,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    vertical: true,
    verticalSwiping: true,
  }

  return (
    <>
      <HeroBlock>
        {isLoaded && (
          <ArrowContainer>
            <Img fluid={arrowImage.childImageSharp.fluid} />
          </ArrowContainer>
        )}
        <Slider {...settings}>
          {hero.map((item, index) => {
            return (
              <Slide key={index}>
                {isLoaded && <Overlay />}
                <Background
                  fluid={item.image.fluid}
                  onLoad={() => setIsLoaded(true)}
                  loading={index === 0 ? "eager" : "lazy"}
                />
              </Slide>
            )
          })}
        </Slider>
      </HeroBlock>
      <HeroMobile>
        {isLoaded && (
          <ArrowContainer>
            <Img fluid={arrowImage.childImageSharp.fluid} />
          </ArrowContainer>
        )}
        <Slider {...settings}>
          {hero.map((item, index) => {
            return (
              <Slide key={index}>
                {isLoaded && <Overlay />}
                <Background
                  fluid={item.mobileImage ? 
                    item.mobileImage.fluid
                    : 
                    item.image.fluid
                  }
                  onLoad={() => setIsLoaded(true)}
                  loading={index === 0 ? "eager" : "lazy"}
                />
              </Slide>
            )
          })}
        </Slider>
      </HeroMobile>
    </>
  )
}

export default Hero
